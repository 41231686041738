import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import { IconButtonAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';

// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
}));

// ----------------------------------------------------------------------
DashboardLayout.propTypes = {
  onOpenSidebar: PropTypes.func,
};
export default function DashboardLayout(onOpenSidebar) {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
