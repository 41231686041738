import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <>
      <Helmet>
        <title>{`${title} | beetter.co`}</title>
        {meta}
      </Helmet>

      <Box
        sx={{
          paddingTop: !isHomePage && { xs: '15px', md: '25px' },
        }}
        ref={ref}
        {...other}
      >
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
