import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

export default function App() {
  useEffect(() => {
    ReactGA.initialize('UA-136060771-23');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider>
      <NotistackProvider>
        <MotionLazyContainer>
          <ProgressBarStyle />

          <Router />
        </MotionLazyContainer>
      </NotistackProvider>
    </ThemeProvider>
  );
}
