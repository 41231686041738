// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets

// ----------------------------------------------------------------------

export default function BlockContent() {
  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" direction="column" sx={{ width: 1 }}>
      <Box
        sx={{
          p: 2,
          width: '100%',
          textAlign: 'center', // Centra el texto en todos los tamaños de pantalla
        }}
      >
        <Typography variant="h6" sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
          Haz click o arrastra para subir una imagen
        </Typography>
      </Box>
    </Stack>
  );
}
