// components
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  profile: getIcon('ic_account'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  theme: getIcon('ic_kanban'),
  messages: getIcon('ic_mail'),
  forms: <AutoAwesomeMotionIcon />,
};

const sidebarConfig = [
  {
    // subheader: 'Tu beetter.co',
    items: [
      { title: 'Dashboard', path: '/dashboard/admin', icon: ICONS.dashboard },

      { title: 'Perfil', path: '/dashboard/profile', icon: ICONS.profile },
      {
        title: 'Solicitudes',
        path: '/dashboard/solicitudes',
        icon: ICONS.messages,
      },
      // { title: 'Temas', path: '/dashboard/temas', icon: ICONS.theme },
      // {
      //   title: 'Analíticas',
      //   path: '/dashboard/analiticas',
      //   icon: ICONS.analytics,
      // },
      // {
      //   title: 'Formularios',
      //   path: '/dashboard/form-builder',
      //   icon: ICONS.forms,
      // },
    ],
  },
];

export default sidebarConfig;
