import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      // element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: '/precios',
      // element: <MainLayout />,
      children: [
        { element: <Precios />, index: true },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: '/faq',
      // element: <MainLayout />,
      children: [
        { element: <Faq />, index: true },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: '/aviso-legal',
      // element: <MainLayout />,
      children: [
        { element: <LegalAdvice />, index: true },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: '/politica-privacidad',
      // element: <MainLayout />,
      children: [
        { element: <PolPriv />, index: true },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: '/:username',
      // element: <MainLayout />,
      children: [{ element: <UserApp />, index: true }],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/dashboard/admin', element: <PageOne /> },
        { path: '/dashboard/profile', element: <PageTwo /> },
        { path: '/dashboard/solicitudes', element: <Messages /> },
        { path: '/dashboard/temas', element: <Themes /> },
        { path: '/dashboard/analiticas', element: <Analytics /> },
        { path: '/dashboard/form-builder', element: <FormBuilderPage /> },
        { path: '/dashboard/backend', element: <Backend /> },
        // {
        //   path: '/dashboard/user',
        //   children: [
        //     { element: <Navigate to="/dashboard/user/four" replace />, index: true },
        //     { path: '/dashboard/user/four', element: <PageFour /> },
        //     { path: '/dashboard/user/five', element: <PageFive /> },
        //     { path: '/dashboard/user/six', element: <PageSix /> },
        //   ],
        // },
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: <Register />,
        // },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-password/:token', element: <ResetPasswordDone /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageOne = Loadable(lazy(() => import('../pages/Admin')));
const PageTwo = Loadable(lazy(() => import('../pages/Profile')));
const Themes = Loadable(lazy(() => import('../pages/Themes')));
const Analytics = Loadable(lazy(() => import('../pages/Analytics')));
const Messages = Loadable(lazy(() => import('../pages/Messages')));

// PRO PAGES
const FormBuilderPage = Loadable(lazy(() => import('../pages/plus/FormBuilderPage')));

// const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Admin backend
const Backend = Loadable(lazy(() => import('../pages/Backend')));

// Login
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ResetPasswordDone = Loadable(lazy(() => import('../pages/auth/ResetPasswordDone')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// UserApp
const UserApp = Loadable(lazy(() => import('../pages/UserApp')));
// Home
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Precios = Loadable(lazy(() => import('../pages/Secondary/Precios')));
const LegalAdvice = Loadable(lazy(() => import('../pages/Secondary/LegalAdvice')));
const PolPriv = Loadable(lazy(() => import('../pages/Secondary/PolPriv')));
const Faq = Loadable(lazy(() => import('../pages/Secondary/Faq')));
